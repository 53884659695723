import style from './index.module.scss'
export const breakpointTabletQuery = `(max-width: ${style['breakpoint-tablet']})`
export const containerMinWidthQuery = `(min-width: ${style['container-large-width']})`

/**
 * A basic wrapper for `window.matchMedia()` that works for server rendering
 */
export const matchMedia = mediaQueryString => {
  // For server rendering, pretend that the media query always matches
  const defaultMatcher = {
    matches: true,
    media: mediaQueryString,

    addListener: () => {},

    removeListener: () => {},
  }
  const mediaMatcher =
    typeof window !== 'undefined' &&
    window.matchMedia &&
    window.matchMedia(mediaQueryString)
  const isValidMatcher =
    mediaMatcher && typeof mediaMatcher.addListener === 'function'
  return isValidMatcher ? mediaMatcher : defaultMatcher
}
