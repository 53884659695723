import React, { useEffect, useReducer, useRef, useState } from 'react'
import classNames from 'classnames'
import { throttle } from '../../helpers/'
import style from './index.module.scss'
import useOnce from '@scentregroup/shared/hooks/use-once'
import { printError } from '@scentregroup/shared/lib'

const BAR_WIDTH = 24
const RESIZE_LIMIT = 50
export const isPositionValid = position => {
  const pos = parseInt(position, 10)
  return pos >= 0 && pos <= 100
}

function useHandleResize() {
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  // useCallback doesn't like throttle but since forceUpdate and
  // RESIZE_LIMIT are fixed we can employ useOnce
  const update = useOnce(() => throttle(forceUpdate, RESIZE_LIMIT))
  useEffect(() => {
    window.addEventListener('resize', update)
    return () => window.removeEventListener('resize', update)
  })
}

function useValidatePosition(position) {
  useEffect(() => {
    if (!isPositionValid(position)) {
      printError(
        `CosmeticScrollar: 'position' prop must be a number between 0 and 100, got ${position}`
      )
    }
  }, [position])
}

function calculateBarPosition(scrollbarRef, percent) {
  const percen = parseInt(percent, 10)
  const scrollAreaWidth = scrollbarRef?.current?.offsetWidth ?? 0
  const barsThatFitIntoScrollArea = scrollAreaWidth / BAR_WIDTH
  const position = barsThatFitIntoScrollArea * percen
  // bar starts inside of the scroll area
  return position - percen
}

const CosmeticScrollbar = ({ lightTheme, position = 0 }) => {
  useHandleResize()
  useValidatePosition(position)
  const scrollbarRef = useRef(null)
  const [translateX, setTranslateX] = useState(0)
  useEffect(() => {
    setTranslateX(calculateBarPosition(scrollbarRef, position))
  }, [position])
  return (
    <div
      aria-hidden="true"
      ref={scrollbarRef}
      className={classNames(style.CosmeticScrollbar, {
        [style.lightTheme]: lightTheme,
      })}
    >
      <div
        className={classNames(style.bar, {
          [style.lightThemeBar]: lightTheme,
        })}
        style={{
          width: `${BAR_WIDTH}px`,
          transform: `translateX(${translateX}%)`,
        }}
      />
    </div>
  )
}

export default CosmeticScrollbar
