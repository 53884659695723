import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { UserDeviceContext } from '../../context'
import { getBrowser, isMobile } from '../get-browser'

export class TouchEventDetection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTouch: false,
    }
  }

  componentDidMount() {
    /**
     * Default `isTouch` to true for known touch devices.
     * This must be done after mounting the component to avoid conflicts with
     * server-side rendering when hydrating.
     *
     * This would normally trigger a new render loop for all of the context
     * consumers, but in practice it gets batched together with re-renders
     * already caused by Apollo queries.
     */
    if (isMobile(getBrowser())) {
      this.setState({ isTouch: true })
    }
  }

  // Handle desktop browsers that are using touch events
  pageTouched = () => {
    this.setState({ isTouch: true })
  }

  render() {
    return (
      <div
        data-name="touch-detection"
        className={this.props.className}
        onTouchStart={this.state.isTouch ? null : this.pageTouched}
      >
        <UserDeviceContext.Provider
          value={{
            isUsingTouch: this.state.isTouch,
          }}
        >
          {this.props.children}
        </UserDeviceContext.Provider>
      </div>
    )
  }
}

TouchEventDetection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
