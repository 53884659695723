import React, { useCallback, useContext, useMemo } from 'react'
import canonicalize from 'canonicalize'

import { Box } from '@hub/box'
import { HubStyleObject } from '@hub/design-system-base'

import {
  trackingTypes,
  trackingNullValue,
  trackingActions,
} from '../../constants'
import { PromotionPositionContext } from '../../context'
import {
  AnalyticsEvent,
  AnalyticsEventWithKey,
  ContentImpressionItem,
} from '../../analytics'
import { TrackBox } from '../../analytics-observer'

const makeBaseEvent = (
  action: 'Viewed' | 'Selected',
  analyticsPropertiesItem: ContentImpressionItem
): AnalyticsEvent => ({
  object: 'Content Impression',
  action,
  properties: {
    items: [analyticsPropertiesItem],
  },
})

const makeAnalyticsEvent = (
  action: 'Viewed' | 'Selected',
  analyticsPropertiesItem: ContentImpressionItem
): AnalyticsEventWithKey => {
  const baseEvent = makeBaseEvent(action, analyticsPropertiesItem)
  // baseEvent should be stable per component so we can use it to
  // generate a uniqueness key, which should be a string as
  // baseEvent !== undefined
  const uniquenessKey = canonicalize(baseEvent) as string
  return { ...baseEvent, uniquenessKey }
}

const NewTrackable: React.FC<React.PropsWithChildren<TrackableProps>> = ({
  children,
  correlationId,
  displayId,
  title,
  position,
  creative,
  promotion,
  sx,
}) => {
  const promotionPosition = useContext(PromotionPositionContext)
  const analyticsPropertiesItem = useMemo(
    () => ({
      creative_name: creative ?? promotion?.creative ?? trackingNullValue,
      promotion_id: correlationId ?? displayId ?? trackingNullValue,
      promotion_name: title ?? position ?? trackingNullValue,
      creative_slot: promotionPosition ?? position ?? trackingNullValue,
      location_id: correlationId ?? displayId ?? trackingNullValue,
    }),
    [
      correlationId,
      creative,
      displayId,
      position,
      promotionPosition,
      promotion?.creative,
      title,
    ]
  )
  const onImpressionTrackEventOnce = useCallback(
    () => makeAnalyticsEvent('Viewed', analyticsPropertiesItem),
    [analyticsPropertiesItem]
  )
  const onClickTrackEvent = useCallback(
    () => makeAnalyticsEvent('Selected', analyticsPropertiesItem),
    [analyticsPropertiesItem]
  )
  return (
    <TrackBox
      sx={sx}
      onImpressionTrackEventOnce={onImpressionTrackEventOnce}
      onClickTrackEvent={onClickTrackEvent}
    >
      {children}
    </TrackBox>
  )
}

// TODO: It's possible <OldTrackable> can be retired altogether
const OldTrackable: React.FC<React.PropsWithChildren<TrackableProps>> = ({
  children,
  trackingAction,
  type,
  correlationId,
  displayId,
  title,
  position,
  creative,
  promotion,
  sx,
}) => {
  const promotionPosition = useContext(PromotionPositionContext)
  return (
    <Box
      sx={sx}
      {...(type === trackingTypes.promotion && {
        'data-promotion': true,
      })}
      data-trackable-action={trackingAction}
      data-trackable-correlation-id={
        correlationId || displayId || trackingNullValue
      }
      data-promotion-id={correlationId || displayId || trackingNullValue}
      data-promotion-name={title || position || trackingNullValue}
      data-promotion-creative={
        creative || (promotion && promotion.creative) || trackingNullValue
      }
      data-promotion-slot={promotionPosition || position || trackingNullValue}
    >
      {children}
    </Box>
  )
}

const Trackable: React.FC<React.PropsWithChildren<TrackableProps>> = props => {
  if (props.trackingAction === trackingActions.viewPromotion) {
    return <NewTrackable {...props} />
  } else {
    return <OldTrackable {...props} />
  }
}

export interface TrackableProps {
  trackingAction: string
  type: string
  correlationId?: string | null
  displayId?: string
  title?: string | null
  position?: string
  creative?: string | null
  promotion?: PromotionProps | null
  sx?: HubStyleObject
}

interface PromotionProps {
  creative?: string | null
}

export default Trackable
