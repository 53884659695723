import React, { ReactElement } from 'react'
import { isTrustedUrl, MaybeURL } from '../../helpers/url'
import PotentialLink from '../potential-link'
import { useGetHrefContext } from '../../context'

export function processLink(
  link?: string,
  currentHref?: string
): string | undefined {
  if (!link) {
    return undefined
  }
  const origin = new MaybeURL(link)?.origin
  const looksExternal = Boolean(origin)
  const reallyInternal =
    looksExternal && new MaybeURL(currentHref)?.origin === origin
  const isExternal = looksExternal && !reallyInternal
  let processedLink = link
  if (reallyInternal) {
    processedLink = (link || '').slice((origin || '').length)
  } else if (isExternal) {
    processedLink = isTrustedUrl(link)
  }
  return processedLink
}

const AutoPotentialLink: React.FC<
  React.PropsWithChildren<AutoPotentialLinkProps>
> = ({ link, ...props }): ReactElement => {
  const currentHref = useGetHrefContext()()
  const processedLink = processLink(link, currentHref)
  return (
    <div>
      <PotentialLink {...props} link={processedLink} />
    </div>
  )
}

export interface AutoPotentialLinkProps {
  children: React.ReactNode
  link?: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
  external?: boolean
}

export default AutoPotentialLink
