/* eslint jsx-a11y/no-redundant-roles: 0 */
import React from 'react'
import classNames from 'classnames'
import { Text } from '@hub/text'
import { summariseOccurrences } from '../../../helpers/occurrences'
import AutoPotentialLink from '../../auto-potential-link'
import ResponsiveImage from '../../responsive-image'
import Trackable from '../../trackable'
import { trackingActions, trackingTypes } from '../../../constants'
import { DeckCardProps } from '../../../../../apps/website/components/page-templates/modules/content-collection/whats-happening-deck'
import style from './index.module.scss'

export interface DeckCardProp extends DeckCardProps {
  title?: string
  className?: string
  externalLink?: boolean
  occurrences?: {
    summary: boolean
    times: {
      startAt: string
      endAt: string
    }[]
    timeZone: string
  }
  location?: string
  onClick?: () => void
  padded?: boolean
  trackers?: {
    clicked: (body: string) => void
  }
}

const DeckCard = ({
  className,
  body,
  image,
  link,
  externalLink,
  occurrences,
  location,
  onClick,
  analyticsMetadata,
  padded = false,
  trackers,
}: DeckCardProp): JSX.Element => {
  const hasOccurrences = Boolean(
    occurrences && occurrences.times && occurrences.times.length
  )
  return (
    <li
      className={classNames(
        style.DeckCard,
        style.evolved,
        { [style.padded]: padded },
        className
      )}
      role="listitem"
      data-test-id="card"
    >
      <Trackable
        {...analyticsMetadata}
        trackingAction={trackingActions.viewPromotion}
        type={trackingTypes.promotion}
        creative={analyticsMetadata?.promotion?.creative}
      >
        <AutoPotentialLink
          link={link}
          className={style.link}
          external={externalLink}
          onClick={() => {
            onClick?.()
            trackers?.clicked?.(body)
          }}
        >
          <ResponsiveImage
            className={style.image}
            desktopImageUrl={image}
            mobileImageUrl={image}
            mobileRatio="square"
            desktopRatio="square"
            numberOfItems={4}
            alt=""
            dataTestId="card-image"
          />
          <div className={style.cardBody}>
            <div className={style.cardInner} data-test-id="card-details">
              {(hasOccurrences || location) && (
                <div className={style.metaWrapper}>
                  {hasOccurrences && (
                    <Text
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textDecoration: 'ellipsis',
                      }}
                    >
                      {summariseOccurrences(
                        occurrences?.times,
                        occurrences?.timeZone
                      )}
                    </Text>
                  )}
                  {location && (
                    <Text
                      sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textDecoration: 'ellipsis',
                      }}
                    >
                      {location}
                    </Text>
                  )}
                </div>
              )}
              <Text
                tone="strong"
                sx={{
                  fontSize: 'font-sm',
                  textOverflow: 'ellipsis',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                }}
              >
                {body}
              </Text>
            </div>
          </div>
        </AutoPotentialLink>
      </Trackable>
    </li>
  )
}

export default DeckCard
